import React from "react"
import { Link } from "gatsby" // highlight-line
import Container from "../components/container.js"
import style from "./index.module.css"

export default function Home() {
  return (
    <Container>
      <div className={style.index}>
          <h4>Interests</h4>
          <p>I am interested in innovation policy, machine learning, and technologies that power the web (this website is built in <a href="https://gatsbyjs.com" target='_blank'>Gatsby</a>). In my free time, I like to go running and hiking :).</p>
          <h4>Articles and projects</h4>
          <p>I posted some of my articles and projects <Link to="/articles/">here</Link>.</p>
          <p>Most of my projects live on my <a href="https://github.com/stefanbielmeier" target="_blank"> GitHub</a> as well, especially schoolwork.</p>
          <h4>About me</h4>
          <p>I'm a graduate student in Management &amp; Technology at <a href='https://www.tum.de' target='_blank' rel="noreferrer">Technical University of Munich</a>. I'm also doing an honors degree in Technology Management at <a href='https://www.cdtm.de' target='_blank' rel="noreferrer">CDTM</a>.
          At the moment, I am completing my last semester via an exchange at <a href='https://berkeley.edu' target='_blank' rel="noreferrer">UC Berkeley</a>. 
          </p> 
          <p>Disclaimer: this website may be outdated!</p>
        </div>
    </Container>
    )
}
